import React from 'react';
import MarketingLayout from '../../components/layout/MarketingLayout';
import PageHeader from '../../components/layout/PageHeader';
import NonprofitForm from '../../components/nonprofit/NonprofitForm';
import PageSection from '../../components/ui/PageSection';
import Spacing from '../../components/ui/Spacing';

const NonprofitPage: React.SFC = () => (
  <MarketingLayout title="Request Nonprofit or Educational Discount">
    <Spacing bottom={5}>
      <PageHeader
        title="Nonprofit or Education"
        description="Slab is offers discounts for both nonprofit and educational use."
      />
    </Spacing>
    <PageSection narrow>
      <Spacing bottom={5}>
        <NonprofitForm />
      </Spacing>
    </PageSection>
  </MarketingLayout>
);

export default NonprofitPage;
