import { navigate } from 'gatsby';
import React from 'react';
import submitForm from '../../utils/submitForm';
import Form, { Field, Submit } from '../ui/Form';
import Link from '../ui/Link';
import Spacing from '../ui/Spacing';
import Text from '../ui/Text';
import styles from './NonprofitForm.module.scss';

type NonprofitFormValues = {
  email: string;
  host: string;
  teamType: string;
  plan: string;
  website: string;
  comment: string;
};

const handleSubmit = (values: NonprofitFormValues) =>
  submitForm<NonprofitFormValues>(process.env.FORM_ID_NONPROFIT, values).then(
    () =>
      navigate(`${window.location.pathname.replace(/\/+$/, '')}/confirmation`),
  );

const NonprofitForm: React.SFC = () => (
  <Form
    initialValues={{
      email: '',
      host: '',
      teamType: '',
      plan: '',
      website: '',
      comment: '',
    }}
    onSubmit={handleSubmit}
    validateOnBlur
    validateOnChange
  >
    <Field
      name="email"
      label="Email Address"
      type="email"
      required
      autoComplete="email"
      placeholder="john@stanford.edu"
    />
    <Field
      name="host"
      label="Slab Team URL"
      type="text"
      required
      placeholder="stanford.slab.com"
      help={
        <>
          Don&apos;t have an account?{' '}
          <Link to="/register/" track={{ label: 'nonprofit-form' }}>
            <Text xxSmall link heavyUnderline>
              Create a free team
            </Text>
          </Link>
        </>
      }
    />
    <fieldset>
      <legend>Purpose</legend>
      <div className={styles.radioGroup}>
        <div className={styles.radio}>
          <Field
            name="teamType"
            value="nonprofit"
            label={<Text small>Nonprofit</Text>}
            type="radio"
            required
          />
        </div>
        <div className={styles.radio}>
          <Field
            name="teamType"
            value="educational"
            label={<Text small>Educational</Text>}
            type="radio"
            required
          />
        </div>
      </div>
    </fieldset>
    <fieldset>
      <legend>Plan</legend>
      <div className={styles.radioGroup}>
        <div className={styles.radio}>
          <Field
            name="plan"
            value="startup"
            label={
              <>
                <Text small>Startup</Text>
                <div className={styles.secondaryLabel}>
                  <Text small muted>
                    · 100% free
                  </Text>
                </div>
              </>
            }
            type="radio"
            required
          />
        </div>
        <div className={styles.radio}>
          <Field
            name="plan"
            value="business"
            label={
              <>
                <Text small>Business</Text>
                <div className={styles.secondaryLabel}>
                  <Text small muted>
                    · 50% off
                  </Text>
                </div>
              </>
            }
            type="radio"
            required
          />
        </div>
      </div>
    </fieldset>
    <Field
      name="website"
      label="Nonprofit or School Website"
      type="text"
      required
      placeholder="https://www.stanford.edu/"
    />
    <Field
      name="comment"
      label="Additional Comment (optional)"
      type="textarea"
      placeholder="Anything else we should know?"
    />
    <Spacing top={2}>
      <Submit block disableUntilValid color="red">
        Request Discount
      </Submit>
    </Spacing>
  </Form>
);

export default NonprofitForm;
